:root {
  --maxScale: scale(1.1);
  --animationSpeed: 2s;
  --animationDelay: 2s;
  --animationEasing: ease-in-out;
}

.header__logo {
  height: 60px;
  padding: 8px;
  width: 60px;
}

.header__logo .logo__v {
  fill: var(--primaryColor);
}

.header__logo .logo__l {
  fill: var(--primaryTextColor);
}

.header__logo .logo__slash {
  fill: var(--secondaryColor);
}
