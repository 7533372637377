.container {
  margin: 0 auto;
  max-width: var(--supportedLargeWidth);
}

.section {
  display: flex;
  padding: 40px 20px;
}

.section:not(:last-child) {
  border-bottom: 1px solid var(--primaryColor);
}

.row > * {
  flex: 1;
  padding: 0 20px;
}

.leftToRight {
  flex-direction: row;
}

.heading {
  align-items: center;
  background-color: var(--primaryTextColor);
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 40px 20px;
  text-align: center;
}

.rightToLeft {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;
}

.technologies {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  width: 400px;
}

.technology {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  line-height: 2;
  text-decoration: none;
  width: 100px;
}

.title {
  margin: 0 0 20px;
  text-align: center;
}

.cta {
  margin-top: 8px;
}

@media (max-width: 768px) {
  .leftToRight {
    flex-direction: column;
  }

  .rightToLeft {
    flex-direction: column;
  }
}
