.header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.titleContainer {
  align-self: center;
  flex: 1;
  padding-left: 8px;
}

.title {
  color: var(--primaryTextColor);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 4px;
  text-decoration: none;
}

.links {
  display: flex;
  margin: 0;
  padding: 0;
  transition: all 0.1s ease;
}

.link {
  align-items: center;
  background: #fff;
  border: none;
  color: #000;
  display: flex;
  font-weight: 700;
  justify-content: center;
  padding: 0 16px;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

.link:hover {
  color: var(--primaryColor);
  cursor: pointer;
}

.flex {
  flex: 1;
}

.smallMenuHeader {
  display: none;
}

.closeButton {
  background: #fff;
  border: none;
  padding: 0 16px;
}

.link:focus,
.closeButton:focus {
  outline: none;
}

.v {
  color: var(--primaryColor);
}

.sy {
  color: var(--secondaryColor);
}

.lo {
  color: var(--primaryTextColor);
}

.smallOnly {
  display: none;
}

.noScroll {
  overflow: hidden;
}

@media (max-width: 768px) {
  .modalOpen {
    opacity: 1;
    position: fixed;
    z-index: 10;
  }

  .modalClosed {
    opacity: 0;
    position: absolute;
    z-index: -10;
  }

  .links {
    background-color: #fff;
    bottom: 0;
    flex-direction: column;
    left: 0;
    right: 0;
    top: 0;
  }

  .links > *:not(.smallMenuHeader) {
    flex: 1;
  }

  .smallMenuHeader {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: space-between;
  }

  .largeOnly {
    display: none;
  }

  .smallOnly {
    display: block;
  }
}
