.container {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  max-width: var(--supportedLargeWidth);
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.form > * {
  margin-bottom: 8px;
}

.form input,
.form textarea {
  border: var(--secondaryColor) solid 1px;
  border-radius: 2px;
  font-size: 14px;
  max-width: 400px;
  padding: 16px 8px;
  width: 95%;
}

.form textarea {
  height: 200px;
}

.submitted {
  padding: 40px;
}

.submitButton {
  font-size: 16px;
  max-width: 420px;
  width: 100%;
}

.description {
  padding-bottom: 16px;
}
