.hero {
  background-color: var(--primaryColor);
  color: #fff;
  padding: 60px 35px;
  text-align: center;
}

.hero h1 {
  margin: 0;
}
